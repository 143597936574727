/* sass-lint:disable no-important */
@media print {
    @font-face {
        font-family: "TarotDotCom-SVG";
        src: local("TarotDotCom"),
        url("https://gfx.tarot.com/fonts/tarotdotcom.woff2") format("woff2"),
        url("https://gfx.tarot.com/fonts/tarotdotcom.woff") format("woff");
        unicode-range: U+003f, U+211E, U+2160-216B, U+25A1, U+25B3,
        U+2601, U+2603, U+2609-260D, U+263D-2653, U+26B7, U+26B9, U+26BB,
        U+2BD3, U+2BF2, U+10FFF0-10FFF8, U+1D403;
    }

    .symbol {
        font-family: "TarotDotCom-SVG" !important;
        font-style: normal;
    }

    h1,
    h2,
    h3,
    h4 {
        break-after: avoid-page;
    }

    .page-break {
        break-before: page;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 11px;
    }

    h1 {
        font-size: 24px;
        line-height: 32px;
        margin: 0 0 11px;
    }

    h2 {
        font-size: 20px;
        line-height: 28px;
    }

    h3 {
        font-size: 18px;
        line-height: 26px;
    }

    h4 {
        font-size: 16px;
        line-height: 24px;
    }

    main {
        margin: 0 auto !important;
        padding: 0 !important;
    }

    .is-hidden-print,
    nav-bar-full,
    daily-nav,
    .sidebar,
    .div[class^="ad-"],
    .article-menu {
        display: none !important;
    }

    @each $alignment in left, center, right {
        .has-text-#{$alignment}-print {
            text-align: #{$alignment};
        }
    }

    @for $s from 1 through 12 {
        column.is-#{$s}-print {
            width: calc(($s / 12) * 100%) !important;
        }
    }

    tarot-report {
        .interp-accordion {
            .interp-accordion-item {
                break-inside: avoid;
                margin-bottom: 10px;

                &::after {
                    display: none;
                }
            }

            .interp-accordion-item-content img {
                padding: 5px 3px 0 0;
                width: 150px;
            }

        }
    }

    .report-item {
        border: none !important;
        display: block !important;

        .report-item-content {
            display: block !important;
            max-height: none !important;
            opacity: 1 !important;
        }
    }

    user-menu {
        display: none !important;
    }

    footer {
        display: none;
    }

    .product-logo {
        display: block;
        margin: 0 auto;
        max-width: 75px;
    }

    .print-links {
        display: block;
        height: 16px;
        line-height: 16px;
        margin: 0 0 0 5px;
        padding: 0;
        width: 100%;
    }

    body {
        &.is-dev,
        &.is-stage,
        &.is-live {
            &::before {
                display: none;
            }
        }
    }

    astrology-compatibility-report {
        ascom-landing,
        ascom-report,
        glossary {
            display: none;

            &.is-shown {
                display: revert;
            }
        }

        .ascom-header {
            display: none;
        }

        ascom-landing {
            display: revert;
        }

        ascom-report {
            display: revert;

            section {
                display: revert;
            }
        }

        glossary {
            display: revert;

            section {
                display: revert;
            }
        }

        .report-item-content {
            display: block !important;
            max-height: none !important;
            min-height: auto !important;
            opacity: 1 !important;
        }
    }

    essential-birth-report,
    ultimate-love-report,
    your-birth-chart {
        orphans: 3;

        ebr-landing row > column.print-cover,
        ulr-landing row > column.print-cover {
            padding: 0 0 0 15px !important;
        }

        .report-item {
            p {
                break-inside: avoid;
            }
        }

        ebr-landing,
        ebr-report,
        ulr-landing,
        ulr-report,
        glossary {
            display: none;

            &.is-shown {
                display: revert;
            }
        }

        .ebr-header,
        .ulr-header {
            display: none;
        }

        ebr-landing,
        ulr-landing {
            display: revert;
        }

        ebr-report,
        ulr-report {
            display: revert;

            section {
                display: revert;
            }

            glossary {
                display: revert;

                section {
                    display: revert;
                }
            }

            .ebr-print-footer,
            .ulr-print-footer {
                display: block;
            }

            .report-item-content {
                display: block !important;
                max-height: none !important;
                min-height: auto !important;
                opacity: 1 !important;
            }
        }
    }

    ascom-landing,
    ebr-landing,
    ulr-landing {
        margin: 0 auto;
        max-width: 90%;
    }

    .report-item a::after,
    nav-bar-mobile,
    tooltip {
        display: none !important;
    }

    .is-sticky {
        position: static;
    }

    .ascom-print-footer,
    .ebr-print-footer,
    .ulr-print-footer {
        display: none;
        margin-top: 10px;

        img {
            height: 25px;
        }
    }
}

/* sass-lint:enable no-important */